<template>
  <div>
    <v-dialog
      v-model="modal"
      width="90%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title class="title">
          {{ type == "add" ? $t("app.add") : $t("app.edit") }}
          Weekly Report
          <v-spacer></v-spacer>
          <v-icon @click="handleForm">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" md="7">
              <v-form ref="form_weekly" v-model="form_valid" lazy-validation>
                <v-text-field
                  v-model="form.title"
                  :rules="[v => !!v || $t('app.required')]"
                  label="Judul"
                  outlined
                  dense
                ></v-text-field>

                <VueEditor
                  ref="weeklyContent"
                  class="caption"
                  v-model="form.content"
                  :editorToolbar="toolbar"
                  :customModules="customModulesForEditor"
                  :editorOptions="editorSettings"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                >
                </VueEditor>
              </v-form>

              <v-divider
                v-if="$vuetify.breakpoint.smAndDown"
                class="my-6"
              ></v-divider>
            </v-col>
            <v-col cols="12" md="5">
              <div class="font-weight-bold mb-2">Indicator</div>
              <div class="indicator-class">
                <v-btn
                  v-for="(row, i) in selected_indicator"
                  :key="i"
                  color="primary"
                  class="ma-1"
                  depressed
                  small
                  @click="deleteIndicator(row)"
                >
                  {{ row }} <v-icon right small>mdi-close</v-icon>
                </v-btn>
              </div>

              <div class="mt-4 mb-2 font-weight-bold">Select Indicator</div>
              <div class="indicator-class">
                <v-btn
                  v-for="(row, i) in list_indicator"
                  :key="i"
                  :disabled="disabledIndicator(row)"
                  color="primary"
                  class="ma-1"
                  outlined
                  depressed
                  small
                  @click="selected_indicator.push(row)"
                >
                  {{ row }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="px-6 py-4 justify-center justify-sm-end">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-btn
            class="gradient-primary white--text mr-2"
            @click="save"
            depressed
          >
            Simpan Draft
          </v-btn>
          <v-btn
            class="gradient-primary-dark white--text"
            @click="handleForm"
            depressed
          >
            {{ $t("app.publish") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

export default {
  props: {
    modal: Boolean,
    handleForm: Function
  },
  components: { VueEditor },
  data() {
    return {
      form_valid: false,
      type: "add",
      toolbar: [
        [{ header: 1 }, { header: 2 }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "blockquote", "code-block"],
        [{ align: [] }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["formula", "image", "video", "clean"]
      ],
      customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize }
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      form: {
        title: "",
        content: ""
      },
      selected_indicator: [],
      list_indicator: ["asdasd", "wewewewewewe"]
    };
  },
  computed: {
    editor() {
      return this.modal && this.$refs.weeklyContent.quill;
    }
  },
  methods: {
    disabledIndicator(row) {
      return this.selected_indicator.filter(s => s == row).length > 0;
    },
    deleteIndicator(row) {
      this.selected_indicator = this.selected_indicator.filter(s => s !== row);
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("image", file);

      // axios({
      //   url: "https://fakeapi.yoursite.com/images",
      //   method: "POST",
      //   data: formData
      // })
      //   .then(result => {
      // let url = result.data.url; // Get url from response
      Editor.insertEmbed(
        cursorLocation,
        "image",
        "https://dummyimage.com/100x120/000000/222dc7&text=asdadasd"
      );
      resetUploader();
      // })
      // .catch(err => {
      //   console.log(err);
      // });
    },
    save() {
      if (this.$refs.form_weekly.validate()) {
        console.log(this.form);
      }
    }
    // onEditorBlur(quill) {
    //   console.log("editor blur!", quill);
    // },
    // onEditorFocus(quill) {
    //   console.log("editor focus!", quill);
    // },
    // onEditorReady(quill) {
    //   console.log(quill);
    // },
    // onEditorChange({ quill, html, text }) {
    //   console.log("editor change!", quill, html, text);
    //   this.content = html;
    // }
  }
};
</script>

<style lang="scss">
.indicator-class {
  min-height: 50px;
  max-height: 300px;
  overflow: auto;
}
.ql-symbol {
  width: 100px;
}
</style>

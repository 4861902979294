<template>
  <div>
    <v-card class="mx-4 mt-4">
      <v-card-text class="px-0">
        <div class="mx-2">
          <v-btn to="bk" text small>
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            <span class="body-2 font-weight-bold">{{
              $t("weekly.weekly_report_detail")
            }}</span>
          </v-btn>
        </div>
        <v-divider class="my-4"></v-divider>
        <v-row class="mx-4">
          <v-col cols="6" sm="2">
            <div class="caption">{{ $t("weekly.name") }}</div>
            <div class="font-weight-bold body-2">{{ student.name }}</div>
          </v-col>
          <v-col cols="6" sm="2">
            <div class="d-flex">
              <v-divider
                v-if="$vuetify.breakpoint.smAndUp"
                vertical
                class="mr-3"
              ></v-divider>
              <div>
                <div class="caption">
                  {{ $t("weekly.registration_number") }}
                </div>
                <div class="font-weight-bold body-2">{{ student.number }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="2">
            <div class="d-flex">
              <v-divider
                v-if="$vuetify.breakpoint.smAndUp"
                vertical
                class="mr-3"
              ></v-divider>
              <div :class="$vuetify.breakpoint.xsOnly && 'mt-2'">
                <div class="caption">{{ $t("weekly.class") }}</div>
                <div class="font-weight-bold body-2">{{ student.class }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="2">
            <div class="d-flex">
              <v-divider
                v-if="$vuetify.breakpoint.smAndUp"
                vertical
                class="mr-3"
              ></v-divider>
              <div :class="$vuetify.breakpoint.xsOnly && 'mt-2'">
                <div class="caption">{{ $t("weekly.type") }}</div>
                <div class="font-weight-bold body-2">{{ student.type }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div
              :class="$vuetify.breakpoint.xsOnly && 'mt-2'"
              class="d-flex align-center"
            >
              <v-btn
                class="gradient-primary white--text mr-2"
                depressed
                @click="modalForm = true"
                >{{ $t("app.add") }}</v-btn
              >
              <v-text-field
                v-model="queryData.search"
                :label="$t('app.search')"
                :class="$vuetify.breakpoint.smAndUp && 'select-175'"
                prepend-inner-icon="mdi-magnify"
                outlined
                dense
                hide-details
              />
            </div>
          </v-col>
        </v-row>

        <v-data-table
          :headers="tableHeaders"
          :items="dataTable"
          :page.sync="queryData.page"
          :items-per-page="queryData.limit"
          :loading="loading"
          hide-default-footer
          class="elevation-0 mx-6 my-4 table-fixed"
        >
          <template v-slot:item.status="{ item }">
            <div>
              <v-tooltip v-if="item.status === 1" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    x-small
                    depressed
                    icon
                    fab
                    class="primary white--text"
                  >
                    <v-icon small>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("weekly.submit_from_teacher") }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.status === 2" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    x-small
                    depressed
                    icon
                    fab
                    class="purple white--text"
                  >
                    <v-icon small>mdi-thumb-up-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("app.approve") }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.status === 3" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    x-small
                    depressed
                    icon
                    fab
                    class="warning white--text"
                  >
                    <v-icon small>mdi-checkbox-marked-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("app.publish") }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.status === 4" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    x-small
                    depressed
                    icon
                    fab
                    class="error white--text"
                  >
                    <v-icon small>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("app.unpublish") }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon depressed outlined small>
                    <v-icon small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list class="py-0 body-2">
                  <v-list-item-group>
                    <v-list-item @click="viewWR(item)">
                      <v-icon class="mr-2" small>mdi-eye</v-icon>
                      {{ $t("app.view") }}
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="mr-2" small>mdi-thumb-up-outline</v-icon>
                      {{ $t("app.approve") }}
                    </v-list-item>
                    <v-list-item @click="modalReject = true">
                      <v-icon class="mr-2" small>mdi-block-helper</v-icon>
                      {{ $t("app.not_approve") }}
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="mr-2" small
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                      {{ $t("app.publish") }}
                    </v-list-item>
                    <v-list-item @click="modalReject = true">
                      <v-icon class="mr-2" small
                        >mdi-close-circle-outline</v-icon
                      >
                      {{ $t("app.unpublish") }}
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="mr-2" small>mdi-delete-forever</v-icon>
                      {{ $t("app.delete") }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>

        <Pagination
          :length="length"
          :total="total"
          :current-page="queryData.page"
          :limit="queryData.limit"
          :handler="paginateChange"
        />
      </v-card-text>
    </v-card>

    <WeeklyReportForm :modal="modalForm" :handleForm="closeForm" />
    <WeeklyReportReject :modal="modalReject" :handleForm="closeReject" />

    <!-- <v-dialog v-model="modalview" width="500" persistent>
      <v-card>
        <v-card-title class="title" primary-title>
          {{ $t("bk.detail_counseling_guidance") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-textarea
                :value="detail.notes"
                :label="$t('bk.notes')"
                outlined
                dense
                readonly
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :value="detail.action"
                :label="$t('app.action')"
                class="my-4"
                outlined
                dense
                readonly
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-img :src="detail.image"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="modalview = false">
            {{ $t("app.back") }}
          </v-btn>
          <v-btn color="primary" depressed @click="publish">
            {{ $t("bk.publish") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import Pagination from "@/components/Pagination";
import WeeklyReportForm from "./WeeklyReportForm";
import WeeklyReportReject from "./WeeklyReportReject";

export default {
  components: {
    Pagination,
    WeeklyReportForm,
    WeeklyReportReject
  },
  data() {
    return {
      appreciation: false,
      total_point: 0,
      loading: false,
      modalview: false,
      modalForm: false,
      modalReject: false,
      length: 0,
      total: 0,
      tableHeaders: [
        {
          text: i18n.t("weekly.table.month"),
          value: "month"
        },
        {
          text: i18n.t("weekly.table.title"),
          value: "title",
          width: 150
        },
        {
          text: i18n.t("weekly.table.status"),
          value: "status",
          align: "center",
          width: 100
        },
        {
          text: i18n.t("weekly.table.received"),
          value: "received",
          width: 120
        },
        {
          text: i18n.t("weekly.table.approved"),
          value: "approved",
          width: 120
        },
        {
          text: i18n.t("weekly.table.shared"),
          value: "shared",
          width: 120
        },
        {
          text: i18n.t("weekly.table.canceled"),
          value: "canceled",
          width: 120
        },
        {
          text: i18n.t("weekly.table.information"),
          value: "information",
          width: 150
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          sortable: false,
          width: 80
        }
      ],
      queryData: {
        page: 1,
        limit: 10,
        sort: "id",
        order: "DESC",
        search: ""
      },
      student: {
        name: "Marion Jola",
        number: "5122",
        class: "1",
        type: "A"
      },
      dataTable: [
        {
          id: 1,
          month: "Desember",
          title: "Judul 1",
          status: 1,
          received: "13 Des 2020",
          approved: null,
          shared: null,
          canceled: null,
          information: null
        },
        {
          id: 2,
          month: "Desember",
          title: "Judul 2",
          status: 2,
          received: "12 Des 2020",
          approved: "13 Des 2020",
          shared: null,
          canceled: null,
          information: null
        },
        {
          id: 3,
          month: "Desember",
          title: "Judul 3",
          status: 3,
          received: "11 Des 2020",
          approved: "11 Des 2020",
          shared: "11 Des 2020",
          canceled: null,
          information: null
        },
        {
          id: 4,
          month: "Desember",
          title: "Judul 4",
          status: 4,
          received: "10 Des 2020",
          approved: null,
          shared: null,
          canceled: "11 Des 2020",
          information: "alasannya"
        }
      ]
    };
  },
  watch: {
    appreciation(param) {
      if (param) {
        console.log(param);
      }
    }
  },
  methods: {
    paginateChange(page, limit) {
      this.page = page;
      this.limit = limit;
      this.initData();
    },
    viewWR() {
      this.modalview = true;
    },
    drawStatus(status) {
      switch (status) {
        case 1:
          return "";
        default:
          break;
      }
    },
    closeForm() {
      this.modalForm = false;
    },
    closeReject() {
      this.modalReject = false;
    }
  }
};
</script>

<template>
  <div>
    <v-dialog v-model="modal" width="500px">
      <v-card>
        <v-card-title class="body-1">
          {{ $t("weekly.input_reason_reject") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="reject"
                :label="$t('weekly.input_reason_reject')"
                textarea
                outlined
                hide-details
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="px-6 py-4 justify-center justify-sm-end">
          <v-btn color="primary" @click="handleForm" outlined>
            {{ $t("app.back") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="gradient-primary white--text"
            @click="handleForm"
            depressed
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modal: Boolean,
    handleForm: Function
  },
  data() {
    return {
      reject: ""
    };
  }
};
</script>
